import { Link } from 'gatsby';
import React from 'react';

import HomePal from './HomePal';
import LifePal from './LifePal';


function InsuranceCards() {

	return(
		<>
			<div className="dropdown-menutest">
				<ul className="droplist">
					<li>
						<a href="https://app.getmybubble.com/lifescore" target="_blank" rel="noopener noreferrer" className=''>
              Bubble LifeScore<sup className='fw500'>TM</sup>
						</a>
					</li>

					<li>
						<a href="https://app.getmybubble.com/homescore" target="_blank" rel="noopener noreferrer"className=''>
              HomeScore
						</a>
					</li>

					<li>
						<a href="https://www.getmybubble.com/blog/partner-widgets/" target="_blank" rel="noopener noreferrer"className=''>
              Widgets
						</a>
					</li>

					<li>
						<Link data-bs-toggle="modal" data-bs-target="#lifepalmodal" rel="noopener noreferrer" className='cursor'>
              LifePal<sup className='fw500'>TM</sup>
						</Link>
					</li>

					<li>
						<Link data-bs-toggle="modal" data-bs-target="#homepalmodal" rel="noopener noreferrer" className='cursor'>
              HomePal<sup className='fw500'>TM</sup>
						</Link>
					</li>
				</ul>
			</div>

			<div className="modal fade" id="lifepalmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body">
							<LifePal/>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="homepalmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog  modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body">
							<HomePal/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default InsuranceCards;
