import '../styles/lifepal.css';

import React from 'react';

function LifePal() {

	return(
		<p>LifePal<sup>TM</sup>, embedded within Bubble’s life insurance shopping experience, is an AI-guided recommendation engine that makes finding the right life insurance fast, easy, and personalized.

			<br/>

Tired of sifting through endless life insurance options? Here’s how LifePal<sup>TM</sup> makes it simple:

			<br/>

			<ol className='lifepal-list'>
				<li><strong>Tell us about yourself</strong>: Share your age, health, lifestyle, and goals. No need for lengthy forms - just quick, easy questions.</li>

				<li><strong>We crunch the numbers</strong>: Our AI, trained on massive amounts of data, analyzes your info and anonymized data trends.</li>

				<li><strong>Personalized picks</strong>: Forget generic options. LifePal™ curates policies from top insurers that perfectly match your needs and budget.</li>

				<li><strong>Transparency matters</strong>: See clear comparisons of coverage amounts, terms, and features side-by-side.</li>

				<li><strong>Expert guidance</strong> Get answers to your questions and insights into each policy to make an informed decision.</li>
			</ol>

Give Bubble’s LifePal<sup>TM</sup>-powered life insurance shopping a test drive. Get started today and enjoy peace of mind for tomorrow.</p>
	);
}

export default LifePal;
