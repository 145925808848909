import '../styles/partners.scss';

import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import assurity from '../images/assurity.png';
import  iconback from '../images/icon-back-black.svg';
import  iconback2 from '../images/icon-back-black.svg';
import  iconorange from '../images/icon-back-orange.svg';
import  iconorange2 from '../images/icon-back-orange-2.svg';
import lglogo from '../images/legal-general.png';
import Nationwide from '../images/nationwidelogo.png';
import northamerica from '../images/north-america.png';
import prudential from '../images/prudential.png';
import Safeco from '../images/safecohome.png';
import SBLI from '../images/sbli-sm.jpg';
import StillWater from '../images/stillwater.jpg';
import transamerica from '../images/transamerica.png';
import wnslogo from '../images/wnslogo.svg';


function PartnersData() {

  const [showArrow, setArrow] = useState( 0 );

  function clicked( i ){
    if( showArrow === i ){
      setArrow( 0 );
    } else {
      setArrow( i );
    }
  }

  return(
    <div id="insurancePartnersNew">
      <Accordion>
        <div className="row">
          <div className="col-md-12 col-12 ">
            <Card>
              <Accordion.Toggle onMouseEnter={( e ) => {
                clicked( 1 );
              }}
              onMouseLeave={
                ( e ) => {
                  clicked( 1 );
                }} eventKey='1'>
                <button role='link' onClick={ () => navigate( '/homeowners-insurance' )} className='linkName'>
      Homeowners Insurance
                  <img className="show-sm show-all m-0 hoverarrow"
                    src={showArrow == 1 ?  iconorange2 : iconback2}
                    tabIndex={0} alt="button" role="button" />
                </button>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey='1'>
                <Card.Body>

                  <ul className="droplist">
                    <li>
                      <Link to="/stillwater/">
                        <img src={StillWater} alt="StillWater logo" />
                  Stillwater
                      </Link>
                    </li>

                    <li>
                      <Link to="/homeowners-insurance/nationwide/">
                        <img src={Nationwide} alt="logo" />
          Nationwide
                      </Link>
                    </li>

                    <li>
                      <Link to="/homeowners-insurance/safeco/">
                        <img src={Safeco} alt="logo" />
          Safeco
                      </Link>
                    </li>
                  </ul>

                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>

              <Accordion.Toggle onMouseEnter={( e ) => { clicked( 2 ); }} onMouseLeave={( e ) => {
                clicked( 2 );
              }} eventKey='2'>
                <button role="link" className='linkName' onClick={() =>navigate( '/life-insurance' )}>
      Life Insurance
                  <img className="show-sm show-all m-0 hoverarrow"
                    src={showArrow == 2 ?  iconorange2 : iconback2}
                    tabIndex={0} alt="button" role="button" />
                </button>
              </Accordion.Toggle>


              <Accordion.Collapse eventKey='2'>
                <Card.Body>
                  <ul className="droplist">
                    {/* <h3>Who we work with</h3> */}

                    {/* <li>
                      <Link to="/next-by-pacific-life/">
                        <img src="https://devuiassetscdn.getmybubble.io/images/next-by-pacific-life-1.png" className="imgBox" alt="pacific life logo" />
          Next Term Life
                      </Link>
                    </li> */}

                    <li>
                      <Link to="/SBLI/">
                        <img src={SBLI} alt="logo" />
          SBLI
                      </Link>
                    </li>

                    <li>
                      <Link to="https://cdn.back9ins.com/product-guides/105-LAA2233.pdf" target="_blank">
                        <img src={lglogo} alt="logo" />
          Legal & General
                      </Link>
                    </li>

                    <li>
                      <Link to="https://cdn.back9ins.com/product-guides/441-TransamericaTrendsetterLBBrochure.pdf" target="_blank">
                        <img src={transamerica} alt="logo" />
          Transamerica
                      </Link>
                    </li>

                    <li>
                      <Link to="https://cdn.back9ins.com/product-guides/665-1010826-00002_Term%2BEssential%2BTerm%2BElite%2BConsumer%2BBrochure%2B(1).pdf" target="_blank">
                        <img src={prudential} alt="logo" />
          Prudential Financial
                      </Link>
                    </li>

                    <li>
                      <Link to="https://cdn.back9ins.com/product-guides/550-608NM-1%2BADDvantage%2BTerm%2BConsumer%2BGuide.pdf" target="_blank">
                        <img src={northamerica} alt="logo" />
          North American
                      </Link>
                    </li>

                    <li>
                      <Link to="https://assurity.dmplocal.com/dsc/collateral/Term_Life_Consumer_Brochure.pdf?d03930b82852e26633e6bac5638c4b2c" target="_blank">
                        <img src={assurity} alt="logo" />
          Assurity
                      </Link>
                    </li>

                    <li>
                      <Link to="/wns/">
                        <img src={wnslogo} alt="logo" />
          W&S
                      </Link>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle onMouseEnter={( e ) => {
                clicked( 3 );
              }}
              onMouseLeave={( e ) =>{
                clicked( 3 );
              }}
              eventKey='3'>
                <button role='link' className='linkName'
                  onClick={() => {  navigate( '/auto-insurance' ); }}>
      Auto Insurance
                  <img className="show-sm show-all m-0 hoverarrow"
                    src={showArrow == 3 ?  iconorange2 : iconback2}
                    tabIndex={0} alt="button" role="button" />
                </button>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey='3'>
                <Card.Body>
                  <ul className="droplist">
                    {/* <h3>Who we work with</h3> */}

                    <li>
                      <Link to="https://public-bubble.s3.us-west-2.amazonaws.com/images/autoBroch.pdf" target="_blank">
                        <img src={StillWater} alt="StillWater logo" />Stillwater
                      </Link>
                    </li>

                    <li>
                      <Link to="/auto-insurance/nationwide/">
                        <img src={Nationwide} alt="logo" />
          Nationwide
                      </Link>
                    </li>

                    <li>
                      <Link to="/auto-insurance/safeco/">
                        <img src={Safeco} alt="logo" />
          Safeco
                      </Link>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div></div>
      </Accordion>
    </div>
  );
}

export default PartnersData;
