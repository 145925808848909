import pc from '@getmybubble/bit-uibl-data-driver';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

import * as commonStyles from '../styles/head.module.css';
import Loader from './loader';

const PERMITTED_HUBSPOT_PROPS = [ 'firstname', 'lastname', 'lead_source', 'email', 'phone', 'full_address', 'address', 'city', 'state', 'country', 'zip', 'postal_code_life', 'gender', 'age', 'dob_life', 'interest_type', 'dropoff_url', 'profile_history_url', 'customer_id', 'application_stage', 'counter_offer', 'home_quote_amount', 'life_quote_amount', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'home_insurance_carrier', 'life_insurance_carrier', 'home_policy_number', 'life_policy_number', 'home_latest_premium_amount', 'life_latest_premium_amount', 'last_updated_at','enter_details' ];

const PERMITTED_HUBSPOT_PROPS_OBJ = {};

export default function HubSpotContactUsForm( props ) {

  const [ isLoading, setLoading ] = useState( false );

  const [ details, setdetails ] = useState( { firstName: '', lastName: '', email: '', mobile: '', insuranceType: '', message: '' } );

  const onChange = ( e ) => {
    e.persist();
    console.log( 'change color' );

    const dropdown = document.querySelector( '#type-dropdown' );

    if( dropdown ){
      document.querySelector( '#type-dropdown' ).classList.add( 'boxes' );
    }

    setdetails( { ...details, [e.target.name]: e.target.value } );
    console.log( 'form details: ', details );
  };

  function handler() {
    setLoading( true );
    const data = { firstname: details.firstName, lastname: details.lastName, email: details.email, phone: details.mobile, interest_type: details.insuranceType, enter_details: details.message };
    console.log( 'form details submit: ', data );
    if ( data.firstname == '' ) {
      Swal.fire( 'Please enter a valid first name.' );
      setLoading( false );
    }
    else if ( data.firstName == '' ) {
      Swal.fire( 'Please enter a valid last name.' );
      setLoading( false );
    }
    else if ( data.email == '' ) {
      Swal.fire( 'Please enter a valid email.' );
      setLoading( false );
    }
    else if ( data.phone == '' ) {
      Swal.fire( 'Please enter a valid mobile number.' );
      setLoading( false );
    }
    else if ( data.interest_types == '' ) {
      Swal.fire( 'Please select a insurance.' );
      setLoading( false );
    }
    else if ( data.enter_details == '' ) {
      Swal.fire( 'Please enter the details.' );
      setLoading( false );
    }
    else {
      var mailreg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      var phoneeeg = /^([0-9]{10})$/;
      if ( mailreg.test( data.email ) == false ) {
        console.log( 'wrong email' );
        Swal.fire( 'Please enter a valid email.' );
        setLoading( false );
      }
      else if ( phoneeeg.test( data.phone ) == false ) {
        console.log( 'wrong phone number' );
        Swal.fire( 'Please enter a valid mobile number.' );
        setLoading( false );
      }
      else {
        let input;
        let id;
        input = data;
        input['lead_source'] = 'Bubble';
        let vid;
        let postBody = { properties: [] };
        let hubspotObject = { properties: [] };
        PERMITTED_HUBSPOT_PROPS.forEach( key => {
          if ( input[key] ) { hubspotObject[key] = input[key]; PERMITTED_HUBSPOT_PROPS_OBJ[key] = true; }
        } );

        Object.keys( input ).forEach( key => {
          if ( PERMITTED_HUBSPOT_PROPS_OBJ[ key ] && input[key] ) postBody.properties.push( { property: key, value: input[key] } );
          else console.log( `Invalid hubSpot:key: ${key} # value: ${input[key]}` );
        } );

        pc.BackendService.hubSpotCheckEmail( JSON.stringify( input.email ) ).then( response => {
          if ( response.Payload ) {
            let data = JSON.parse( response.Payload );
            if ( data.errorType ) {
              vid = null;
            } else {
              vid = data.body.response.data.vid;
            }
            if ( vid ) {
              postBody['vid'] = vid;
            }
            postBody.properties.push( {
              property: 'last_updated_at', value: pc.getCurrentDate()
            } );
            let postBodyString = JSON.stringify( postBody );
            console.log( 'postBodyString:', postBodyString );

            if ( !vid ) {
              pc.BackendService.hubSpotSync( postBodyString )
                .then( data => {
                  if ( data.Payload ) {
                    let output = JSON.parse( data.Payload );
                    console.log( 'hubSpotSync:output:', { output } );
                    if ( output.body ) {
                      console.log( 'hubSpotSync:output.data.body:', output.body.response );
                      vid = output.body.response.data.vid;

                      if ( id ) pc.BackendService.saveUpdateClientData( { id, hubSpotId: vid } );
                    } else if ( output.errorMessage ) {
                      console.log( 'hubSpotSync:output.errorMessage:', output.errorMessage );
                    }
                    Swal.fire( 'Thank you for submitting the details.' );
                    setLoading( false );
                  } else {
                    console.log( 'hubSpotSync:data.Payload is null:data: ', data );
                  }
                } );
            } else if ( vid ) {
              //postody = { vid: vid, properties : [] }
              try {
                pc.BackendService.hubSpotUpdate( JSON.stringify( postBody ) ).then( data => {
                  console.log( 'hubSpotUpdate:input:postBody success:', postBody, ' # data: ', data );
                  if ( data.Payload ) {
                    let output = JSON.parse( data.Payload );
                  }
                  setLoading( false );
                  Swal.fire( 'Thank you for submitting the details.' ).then( () => props.closeModal() );

                } ).catch( error => {
                  console.log( 'hubSpotUpdate:input:postBody error:', postBody, ' # error: ', error );
                } );
                //response is no content with status code 204
              } catch ( e ) {
                console.log( 'try:hubSpotUpdate:input:postBody catch:', postBody, ' # eee: ', e );

              }
            }
          } else {
            console.log( 'email doesn\'t exist in HubSpot for: input.email: ', input.email );
          }
        } ).catch( error => {
          console.log( '.hubSpotCheckEmail catch block for::error:', error );
          console.log( '.hubSpotCheckEmail catch block for: input.email: ', input.email );
        } );
      }
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div style={{ textAlign: 'center' }} className="flexColumn align-items-center">
            <b>Get In Touch</b>

            <p className={commonStyles.textOne}>
                          Send in your details and the Bubble team will get on with helping you out
            </p>
          </div>
        </div>

        <div className="col-lg-6">
          <input type="text" className={commonStyles.contacttextboxes} value={details.firstName} onChange={onChange} name="firstName"  placeholder="Enter First Name" ></input>

          <br></br>
        </div>

        <div className="col-lg-6">
          <input type="text" className={commonStyles.contacttextboxes} value={details.lastName} onChange={onChange} name="lastName" placeholder="Enter Last Name"></input>

          <br></br>
        </div>

        <div className="col-lg-6">
          <input type="text" className={commonStyles.contacttextboxes} value={details.email} onChange={onChange} name="email" placeholder="Enter Email ID"></input>

          <br></br>
        </div>

        <div className="col-lg-6">
          <input type="text" className={commonStyles.contacttextboxes} value={details.mobile} onChange={onChange} name="mobile" maxLength="10" placeholder="Enter Mobile Number"></input>

          <br></br>
        </div>

        <div className="col-lg-12 seletboxout">
          {/* <select
            id="type-dropdown"
            className={commonStyles.contacttextboxes}
            style={{ marginBottom: '5%' }}
            defaultValue={''}
            onChange={onChange} name="insuranceType">
            <option value="" className="default" disabled>Interested In</option>

            <option className='options' value="Home">Home Insurance</option>

            <option className='options' value="Life">Life Insurance</option>

            <option className='options' value="HomeLife">Home & Life Insurance</option>
          </select> */}

          <textarea rows="3"
            className={commonStyles.contacttextarea}
            value={details.message}
            onChange={onChange}
            name="message"
            placeholder="Your Message"></textarea>

          <div style={{ textAlign: 'center' }} className="modalfoottext">
            <button className={commonStyles.submitbtn} onClick={handler}>Submit</button>

            <p className={commonStyles.textOne}>
                        By clicking submit you are agreeing to Bubble contacting you on the provided details
            </p>
          </div>
        </div>
      </div>

      {
        isLoading && <Loader />
      }
    </div>

  );
}


